<template>
  <v-scale-transition>
    <BaseForm
      apiActions="board/board.php"
      backRoute="/boardlist"
      newRecordTitle="Novi odbor"
      listRefreshAction="board_list_needs_refresh"
      :formData="[
        {
          title: 'OSNOVNI PODACI',
          rows: [
            {
              id: 1,
              fields: [
                {
                  label: 'Vrsta',
                  type: 'text',
                  name: 'type'
                },
                {
                  label: 'Kratki naziv',
                  type: 'text',
                  name: 'short_name'
                },
                {
                  label: 'Naziv',
                  type: 'text',
                  name: 'name'
                }
              ]
            },
            {
              id: 2,
              fields: [
                {
                  label: 'Napomena',
                  type: 'text',
                  name: 'note'
                }
              ]
            }
          ]
        }
      ]"
    ></BaseForm>
  </v-scale-transition>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";

export default {
  data() {
    return {};
  },
  components: {
    BaseForm
  }
};
</script>
